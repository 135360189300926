//OutputSection.js
import React from 'react';
import './OutputSection.css';

const OutputSection = ({ outputValue, removeQuotes, setRemoveQuotes }) => (
    <div className="output-section">
        <h2>Output:</h2>
        <textarea
            value={outputValue}
            readOnly
        />
        <div className="output-actions">
            <button onClick={() => navigator.clipboard.writeText(outputValue)}>Copy</button>
            <button onClick={() => {
                const blob = new Blob([outputValue], { type: 'text/plain' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'output.txt';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }}>Save</button>
        </div>
    </div>
);

export default OutputSection;
