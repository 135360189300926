// src/conversionUtils.js
export const convertInput = (input, format, jsonOption, jsonAttribute, removeQuotes, removeHashtags, removeTopRow, processLimit) => {
  let result = '';
  let uniqueValues = [];

// Split input into lines and extract the first value from each line assuming CSV structure
const lines = input.split('\n');
const values = lines.map(row => row.split(',')[0]);  // Keep only the first column

// Now perform the rest of the processing
const processedValues = values
  .filter(value => value.trim() !== '')  // Remove empty values
  .map(value => value.replace(/(\r\n|\n|\r)/gm, ""))  // Remove new line characters
  .map(value => value.replace(/,,/g, ','))  // Replace double commas with single commas
  .map(value => value.trim());  // Remove leading and trailing spaces
  
  // Remove duplicates
  uniqueValues = [...new Set(values)];

  // Optional data cleaning
  if (removeQuotes) {
    uniqueValues = uniqueValues.map(value => value.replace(/"/g, ''));
  }
  if (removeHashtags) {
    uniqueValues = uniqueValues.map(value => value.replace(/#/g, ''));
  }
  if (removeTopRow && uniqueValues.length > 1) {
    uniqueValues = uniqueValues.slice(1);
  }
  if (processLimit > 0) {
    uniqueValues = uniqueValues.slice(0, processLimit);
  }

  // Format output based on selected format
  if (format === 'Comma Separated') {
    result = uniqueValues.join(', ');
  } else if (format === 'JSON') {
    let jsonArray = [];
    if (jsonOption === 'Custom' && jsonAttribute) {
      // Generate JSON using the custom JSON attribute provided by the user
      jsonArray = uniqueValues.map(value => ({
        "match_phrase": {
          [jsonAttribute]: value
        }
      }));
    } else if (jsonOption === 'Location') {
      // Generate JSON for location-based queries
      jsonArray = uniqueValues.map(location => ({
        "wildcard": {
          "author_place": `${location}*`
        }
      })).concat(uniqueValues.map(location => ({
        "wildcard": {
          "meta.geo_place.results.value": `${location}*`
        }
      }))).concat(uniqueValues.map(location => ({
        "wildcard": {
          "meta.author_geo_place.results.value": `${location}*`
        }
      })));
    } else {
      // Default JSON output for other options
      jsonArray = uniqueValues.map(value => ({
        "match_phrase": {
          "doc.user.description": value
        }
      })).concat(uniqueValues.map(value => ({
        "match_phrase": {
          "meta.title.results": value
        }
      })));
    }
    result = {
      "bool": {
        "minimum_should_match": 1,
        "should": jsonArray
      }
    };
    result = JSON.stringify(result, null, 2);  // Convert the result to a JSON string
  }

  return result;
};