import React from 'react';
import './Menu.css';

const Menu = () => (
    <div className="menu-container">
        <input type="checkbox" id="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon">&#9776; Menu</label>
        <nav className="menu">
            <h2 className="menu-header">Research Resources</h2>
            <ul>
                <li className="menu-section">
                    <span className="menu-section-header">Plan and Prepare</span>
                    <ul>
                        <li><a href="https://wiki.irregularchat.com/topic/research/research-planning" target="_blank" rel="noopener noreferrer">Research Planning Guide</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/structured-analytic-techniques" target="_blank" rel="noopener noreferrer">Structured Analytic Techniques (SATs)</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/pmesii-pt" target="_blank" rel="noopener noreferrer">PMESII-PT</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/cog" target="_blank" rel="noopener noreferrer">Center of Gravity (COG) Analysis</a></li>
                        <li><a href="https://wiki.irregularchat.com/community/research/about-research-template" target="_blank" rel="noopener noreferrer">Research Templates</a></li>
                    </ul>
                </li>
                <li className="menu-section">
                    <span className="menu-section-header">Gather</span>
                    <ul>
                        <li><a href="https://wiki.irregularchat.com/topic/research/research-datasets" target="_blank" rel="noopener noreferrer">Research Datasets</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/darkweb-links" target="_blank" rel="noopener noreferrer">Darkweb Links</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/research-platforms" target="_blank" rel="noopener noreferrer">Research Tools</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/leaks" target="_blank" rel="noopener noreferrer">Leaks and Compilations</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/research-citation" target="_blank" rel="noopener noreferrer">Citation</a></li>
                        <li><a href="https://wiki.irregularchat.com/topic/research/research-platforms/ai-prompting.md" target="_blank" rel="noopener noreferrer">ai-prompting for advanced queries</a></li>
                    </ul>
                </li>
                <li className="menu-section">
                    <span className="menu-section-header">Process and Analyze</span>
                    <ul>
                        <li><a href="https://wiki.irregularchat.com/community/guides/research/forms" target="_blank" rel="noopener noreferrer">Forms for Processing</a></li>
                        <li><a href="https://wiki.irregularchat.com/community/guides/research/rstudio" target="_blank" rel="noopener noreferrer">R Studio Guide</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
    </div>
);

export default Menu;
