// /src/App.js
import React, { useState, useEffect } from 'react';
import OutputSection from './OutputSection';
import Menu from './Menu';
import { convertInput } from './conversionUtils';
import generateAdvancedQuery from './chatgptService.js';
import './App.css';
import logo from './public/research_chat-mascot_logo.png'; 
import MobileInputSection from './MobileInputSection.js';
import InputSection from './InputSection.js';

function App() {
    const [inputValue, setInputValue] = useState('');
    const [outputValue, setOutputValue] = useState('');
    const [selectedFile, setSelectedFile] = useState([]);
    const [jsonAttribute, setJsonAttribute] = useState('');
    const [jsonOption, setJsonOption] = useState('Custom');
    const [removeQuotes, setRemoveQuotes] = useState(true);  
    const [removeHashtags, setRemoveHashtags] = useState(false);
    const [removeTopRow, setRemoveTopRow] = useState(true); 
    const [processLimit, setProcessLimit] = useState(0);
    const [searchPlatform, setSearchPlatform] = useState('Google'); 
    const [model, setModel] = useState('gpt-3.5-turbo');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [selectedFormat, setSelectedFormat] = useState(isMobile ? 'Advanced Query' : 'Comma Separated'); 

    // Effect for handling window resize
    useEffect(() => {
        const handleResize = () => {
            const mobile = window.innerWidth <= 768;
            setIsMobile(mobile);
            if (mobile) {
                setSelectedFormat('Advanced Query');
                setRemoveQuotes(false);
                setRemoveHashtags(false);
                setRemoveTopRow(false);
                setProcessLimit(0);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleConvert = () => {
        if (selectedFormat === 'Advanced Query') {
            handleAdvancedQuery();
        } else {
            const result = convertInput(
                inputValue,
                selectedFormat,
                jsonOption,
                jsonAttribute,
                removeQuotes,
                removeHashtags,
                removeTopRow,
                processLimit
            );
            setOutputValue(result);
        }
    };

    const handleAdvancedQuery = async () => {
        const query = await generateAdvancedQuery(inputValue, searchPlatform, model); 
        setOutputValue(query);
    };    

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFile(files);

        let allText = '';
        let promises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let fileContent = e.target.result;
                    let lines = fileContent.split('\n');
                    if (removeTopRow) {
                        lines = lines.slice(1);
                    }
                    resolve(lines.join('\n'));
                };
                reader.onerror = reject;
                reader.readAsText(file);
            });
        });

        Promise.all(promises)
            .then(results => {
                allText = results.join('\n');
                // Remove duplicates after combining all file contents
                const uniqueLines = [...new Set(allText.split('\n'))];
                setInputValue(uniqueLines.join('\n'));
            })
            .catch(error => console.error('Error reading files:', error));
    };

    const clearFile = () => {
        setSelectedFile([]);
        setInputValue('');
        setOutputValue('');
        document.querySelector('input[type="file"]').value = null;
    };

    return (
        <div>
            <Menu />
            <header className="App-header">
                <a href="https://researchtools.net/">
                    <img src={logo} className="App-logo" alt="logo" />
                </a>
            </header>
            <div className="app">
                {isMobile ? (
                    <MobileInputSection
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        selectedFormat={selectedFormat}
                        setSelectedFormat={setSelectedFormat}
                        jsonAttribute={jsonAttribute}
                        setJsonAttribute={setJsonAttribute}
                        handleConvert={handleConvert}
                        setOutputValue={setOutputValue}
                        model={model}
                        setModel={setModel}
                    />
                ) : (
                    <InputSection
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        selectedFormat={selectedFormat}
                        setSelectedFormat={setSelectedFormat}
                        jsonAttribute={jsonAttribute}
                        setJsonAttribute={setJsonAttribute}
                        handleConvert={handleConvert}
                        setOutputValue={setOutputValue}
                        handleFileChange={handleFileChange}
                        clearFile={clearFile}
                        selectedFile={selectedFile}
                        jsonOption={jsonOption}
                        setJsonOption={setJsonOption}
                        removeQuotes={removeQuotes}
                        setRemoveQuotes={setRemoveQuotes}
                        removeHashtags={removeHashtags}
                        setRemoveHashtags={setRemoveHashtags}
                        removeTopRow={removeTopRow}
                        setRemoveTopRow={setRemoveTopRow}
                        processLimit={processLimit}
                        setProcessLimit={setProcessLimit}
                        searchPlatform={searchPlatform}
                        setSearchPlatform={setSearchPlatform}
                        model={model}
                        setModel={setModel}
                    />
                )}
                <OutputSection outputValue={outputValue} />
            </div>
            <footer>
                <p>2024 Research Tools. - 
                    <a href="https://github.com/irregularchat/researchtools/" target="_blank" rel="noopener noreferrer">Github Repo</a> |
                    <a href="https://wiki.irregularchat.com/topic/research" target="_blank" rel="noopener noreferrer"> Research Wiki</a>
                </p>
            </footer>
        </div>
    );
}

export default App;