// src/MobileInputSection.js
import React, { useState, useCallback } from 'react';
import './Mobile.css';
import generateAdvancedQuery from './chatgptService.js';

const MobileInputSection = ({
    inputValue,
    setInputValue,
    selectedFormat,
    setSelectedFormat,
    handleConvert,
    setOutputValue
}) => {
    const [selectedFile, setSelectedFile] = useState([]);
    const [searchPlatform, setSearchPlatform] = useState('Google');
    const [jsonOption, setJsonOption] = useState('Custom'); // Initialized to 'Custom' for example
    const [jsonAttribute, setJsonAttribute] = useState('');
    const [model, setModel] = useState('gpt-3.5-turbo');
    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    const handleFileChange = useCallback((event) => {
        const files = Array.from(event.target.files);
        setSelectedFile(files);

        let promises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let lines = e.target.result.split('\n').slice(1);
                    resolve(lines.join('\n'));
                };
                reader.onerror = reject;
                reader.readAsText(file);
            });
        });

        Promise.all(promises)
            .then(results => {
                setInputValue([...new Set(results.join('\n').split('\n'))].join('\n'));
            })
            .catch(error => console.error('Error reading files:', error));
    }, [setInputValue]);

    const handleJsonOptionChange = (event) => {
        setJsonOption(event.target.value);
    };

    const handleSearchPlatformChange = (event) => {
        setSearchPlatform(event.target.value);
    };

    const clearFile = () => {
        setSelectedFile([]);
        setInputValue('');
        setOutputValue('');
        document.querySelector('input[type="file"]').value = null;
    };

    const handleAdvancedQuery = async () => {
        console.log('Processing advanced query'); // Debugging line
        try {
            const query = await generateAdvancedQuery(inputValue, searchPlatform);
            console.log('Query result:', query); // Debugging line
            setOutputValue(query);
        } catch (error) {
            console.error('Error generating advanced query:', error);
            setOutputValue('Error generating advanced query');
        }
    };

    return (
        <div className="input-section">
            <div className="file-upload">
                <input type="file" multiple onChange={handleFileChange} />
                {(selectedFile.length > 0 || inputValue) && (
                    <div>
                        {selectedFile.map(file => file.name).join(', ')}
                        <button onClick={clearFile} className="clear-button">Clear</button>
                    </div>
                )}
            </div>
            <textarea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Paste your text here or upload a file above."
            />
            <select value={selectedFormat} onChange={(e) => setSelectedFormat(e.target.value)}>
                <option value="Advanced Query">Advanced Query</option>
                <option value="Comma Separated">Comma Separated</option>
                <option value="JSON">JSON</option>  
            </select>
            {selectedFormat === 'Advanced Query' && (
            <>
                <select value={searchPlatform} onChange={handleSearchPlatformChange}>
                    <option value="Google">Google</option>
                    <option value="Microsoft Portal">Microsoft Portal</option>
                    <option value="Windows CMD Search">Windows CMD Search</option>
                </select>
                <select value={model} onChange={handleModelChange}>
                    <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                    <option value="gpt-4o">GPT-4o</option>
                </select>
            </>
            )}
            {selectedFormat === 'JSON' && (
                <>
                    <select value={jsonOption} onChange={handleJsonOptionChange}>
                        <option value="Custom">Custom</option>
                        <option value="Keyword">Keyword</option>
                        <option value="Location">Location</option>
                        <option value="Image">Image</option>
                    </select>
                    {jsonOption === 'Custom' && (
                        <input
                            type="text"
                            value={jsonAttribute}
                            onChange={(e) => setJsonAttribute(e.target.value)}
                            placeholder="Enter JSON attribute"
                        />
                    )}
                </>
            )}
            <button onClick={selectedFormat === 'Advanced Query' ? handleAdvancedQuery : handleConvert}>
                Process Data
            </button>
        </div>
    );
};

export default MobileInputSection;