import React, { useState, useCallback } from 'react';
import './InputSection.css';
import generateAdvancedQuery from './chatgptService.js';
import { convertInput } from './conversionUtils';

const InputSection = ({
    inputValue,
    setInputValue,
    selectedFormat,
    setSelectedFormat,
    jsonAttribute,
    setJsonAttribute,
    jsonOption,
    setJsonOption,
    removeQuotes,
    setRemoveQuotes,
    removeHashtags,
    setRemoveHashtags,
    removeTopRow,
    setRemoveTopRow,
    processLimit,
    setProcessLimit,
    setOutputValue,
    handleFileChange,
    selectedFile,
    setSelectedFile
}) => {
    const [searchPlatform, setSearchPlatform] = useState('Google');
    const [model, setModel] = useState('gpt-3.5-turbo');
    
    const handleModelChange = (event) => {
        setModel(event.target.value);
    };

    const clearFile = () => {
        setSelectedFile([]);
        setInputValue('');
        setOutputValue('');
        document.querySelector('input[type="file"]').value = null;
    };
    const handleConvert = () => {
        if (selectedFormat === 'Advanced Query') {
            handleAdvancedQuery();
        const result = convertInput(
            inputValue,
            selectedFormat,
            jsonOption,
            jsonAttribute,
            removeQuotes,
            removeHashtags,
            removeTopRow,
            parseInt(processLimit, 10) || 0
        );
        setOutputValue(result);
    };
};

    const handleJsonOptionChange = (event) => {
        setJsonOption(event.target.value);
    };

    const handleFormatChange = useCallback((event) => {
        const format = event.target.value;
        setSelectedFormat(format);
        if (format === 'Comma Separated') {
            setRemoveQuotes(true);
            setRemoveHashtags(false);
            setRemoveTopRow(true);
        } else if (format === 'Advanced Query') {
            setRemoveQuotes(false);
            setRemoveHashtags(false);
            setRemoveTopRow(false);
        } else {
            setRemoveQuotes(false);
            setRemoveHashtags(false);
            setRemoveTopRow(false);
        }
    }, [setSelectedFormat, setRemoveQuotes, setRemoveHashtags, setRemoveTopRow]);

    const handleSearchPlatformChange = (event) => {
        setSearchPlatform(event.target.value);
    };

    const handleAdvancedQuery = async () => {
        console.log('Processing advanced query'); // Debugging line
        try {
            const query = await generateAdvancedQuery(inputValue, searchPlatform, model); // Pass model to generateAdvancedQuery
            console.log('Query result:', query); // Debugging line
            setOutputValue(query);
            console.log('Output value set:', query); // Debugging line to confirm state update
        } catch (error) {
            console.error('Error generating advanced query:', error);
            setOutputValue('Error generating advanced query');
        }
    };

    return (
        <div className="input-section">
            <div className="file-upload">
                <input type="file" multiple onChange={handleFileChange} />
                {(selectedFile.length > 0 || inputValue.trim()) && (
                    <div>
                        {selectedFile.map(file => file.name).join(', ')}
                        <button onClick={clearFile} className="clear-button">Clear</button>
                    </div>
                )}
            </div>
            <label>Paste your CSV or JSON text here:</label>
            <textarea
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Paste your CSV or JSON text here"
            />
            <div className="conversion-options">
                <select value={selectedFormat} onChange={handleFormatChange}>
                    <option value="Comma Separated">Comma Separated</option>
                    <option value="Advanced Query">Advanced Query</option>
                    <option value="JSON">JSON</option>
                </select>
                {selectedFormat === 'Advanced Query' && (
                <>
                    <select value={searchPlatform} onChange={handleSearchPlatformChange}>
                        <option value="Google">Google</option>
                        <option value="Microsoft Portal">Microsoft Portal</option>
                        <option value="Windows CMD Search">Windows CMD Search</option>
                    </select>
                    <select value={model} onChange={handleModelChange}>
                        <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                        <option value="gpt-4o">GPT4o</option>
                    </select>
                </>
                )}
                {selectedFormat === 'JSON' && (
                    <>
                        <select value={jsonOption} onChange={handleJsonOptionChange}>
                            <option value="Custom">Custom</option>
                            <option value="Keyword">Keyword</option>
                            <option value="Location">Location</option>
                            <option value="Image">Image</option>
                        </select>
                        {jsonOption === 'Custom' && (
                            <input
                                type="text"
                                value={jsonAttribute}
                                onChange={(e) => setJsonAttribute(e.target.value)}
                                placeholder="Enter JSON attribute"
                            />
                        )}
                    </>
                )}
                <div className="checkboxes">
                    <label>
                        <input
                            type="checkbox"
                            checked={removeQuotes}
                            onChange={(e) => setRemoveQuotes(e.target.checked)}
                        />
                        Remove quotes
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={removeHashtags}
                            onChange={(e) => setRemoveHashtags(e.target.checked)}
                        />
                        Remove hashtags
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={removeTopRow}
                            onChange={(e) => setRemoveTopRow(e.target.checked)}
                        />
                        Remove Top Row
                    </label>
                </div>
                <label>
                    Number of Values to Process
                    <input
                        type="number"
                        value={processLimit}
                        onChange={(e) => setProcessLimit(e.target.value)}
                        placeholder="Limit"
                        className="process-limit-input"
                    />
                </label>
                <button onClick={handleConvert}>
                    Process Data
                </button>
            </div>
        </div>
    );    
}
export default InputSection;