// src/chatgptService.js
import axios from 'axios';
import { openai_api_key } from './api.js';

const generateAdvancedQuery = async (searchQuery, searchPlatform, model = 'gpt-3.5-turbo') => {
  const basePrompt = `Analyze the intention of this search and create an advanced query to be used on ${searchPlatform}. `;
  const additionalPrompt = searchPlatform === "Windows CMD Search" ?
    `Take into account the limits and syntax of ${searchPlatform} and use only commands that do not require installation on the terminal.
     Output the exact command to run, do not output any additional explanation, do not use markdown codeblocks.` :
    `Start by considering what the data would look like, what format it would be in, what tld might be using it filetype, etc.
    Take into account the limits and syntax of ${searchPlatform} and use all the advanced operators and boolean logic needed to get the results.
    don't just ouput the same search query, but rather a more advanced one that would get you the results you need.
    Only output this advanced query, do not output any additional explanation.`;

  const response = await axios.post(
    'https://api.openai.com/v1/chat/completions',
    {
      model: model,
      messages: [{ "role": "user", "content": basePrompt + additionalPrompt + searchQuery }],
      max_tokens: 250
    },
    {
      headers: {
        'Authorization': `Bearer ${openai_api_key}`,
        'Content-Type': 'application/json'
      }
    }
  );

  return response.data.choices[0].message.content.trim();
};

export default generateAdvancedQuery;